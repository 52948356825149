body{
	margin: 0;
	padding: 0;
	background: #eeeeee;
}
svg{
	border: 1px solid black;
}
*{
	box-sizing: border-box;
}
body .devModeOnly{
	display: none;
}
body.DEV_MODE .devModeOnly{
	display: block;
}

input.error{
	border-color: #ff4242 !important;
	background-color: #ffb6b6 !important;
}

#topControls{
	position: fixed;
	top: 0;
	left:0;right:0;
	height:72px;
	background: #fcfcfc;
	-webkit-box-shadow: 0px 0px 15px 5px #D9D9D9;
	box-shadow: 0px 0px 15px 5px #D9D9D9;
	z-index: 5;
}
#topControls.hide{
	width: auto;
	right:auto;
}
#topControls.singleRow{
	height: 36px;
}

#topControls .appLogo{
	background: #6100ff;
	width: 36px;height:36px;text-align: center;vertical-align: middle;
	position: absolute;
	z-index: 20;
}
#topControls .appLogo img{
	max-width: 30px;
	max-height: 30px;
	margin-top: 2px;
}
#topControls.hide > .appLogo{
	position: absolute;
	top: 0;
	left: 0;
}

#topControls .tabSelector{
	position: absolute;
	top:0;left: 0;right:0;
	text-align: center;
	height: 35px;
	border-bottom: 1px solid #efefef;
	box-sizing: content-box;
	overflow-x: auto;
	overflow-y: hidden;
	white-space:nowrap;
	padding: 0 30px;
}
#topControls.hide > .tabSelector{
	display: none;
}
#topControls.singleRow .tabSelector{
	border-bottom: none;
}
#topControls .tabSelector.row2{
	top:36px;
}

#topControls .tabSelector .tab{
	display: inline-block;
	padding: 0 15px;
	height: 35px;
	line-height: 35px;
	background: #fcfcfc;
	color: black;
	text-decoration: none;
}
#topControls .tabSelector .tab input[type=checkbox]{
	pointer-events: none;
}
#topControls .tabSelector .tab.selected, #topControls .tabSelector .tab:hover{
	background: #efefef;
	cursor: pointer;
	box-shadow: 0px 0px 0px 5px rgba(160, 160, 160, 0), inset 0px 10px 27px -8px rgba(20, 20, 20, 0), inset 0px -10px 27px -8px #e7e7e7, inset 0px 0px 5px 8px rgba(36,36,36,0);
}
#topControls .tabDisplayer{
	position: absolute;
	top:35px;left: 0;right:0;
	text-align: center;
	height: 125px;
}
#topControls .rightLabel{
	position: fixed;
	right: 10px;
	top: 4px;
	background: #6100ff;
	padding: 5px;
	border-radius: 5px;
	color:white;
}

#mainPageContainer{
	height: calc(100% - 72px);
	width: 100vw;
	position: absolute;
	top: 72px; left: 0;
	overflow: auto;
	padding-top: 15px;
	overflow-x: hidden;
}
#mainPageContainer > div{
	padding: 15px 15px 45px 15px;
}
#mainPageContainer > div.noMargins{
	padding:0;
}
#mainPageContainer.noTopMargin{
	padding:0;
}

#loginContainer{
	position: fixed;
	top:0;left:0;right:0;bottom:0;
	z-index: 9999;
	background: rgba(0,0,0,0.4);
}
#loginContainer iframe{
	border:none;
	width: 100%;
	height: 100%;
}


.segment{
	padding: 20px;
}


